:root {
    --nortonlifelockdark: #242E39;
    --nortonlifelocklight: #E4E4E4;
    --nortonlifelockyellow: #FFC200;
    --nortonlifelockheading: 'Source Sans Pro';
    --nortonlifelockbody: 'Roboto';
    --nortonlifelockHeaderFontSize: calc(12px + 2vmin);
    --nortonlifelockTabFontSize: calc(10px + 1vmin);
    --nortonlifelockTextFontSize: calc(8px + 1vmin);
    --nortonlifelockMiniFontSize: calc(6px + 1vmin);
    --nortonlifelockFormErrorFontSize: calc(6px + 0.5vmin);
    --nortonlifelockFooterFontSize: calc(8px + 0.5vmin);
}


/*** App.js ***/

body {
    /* dark */
    background-color: var(--nortonlifelockdark);
    /* main body font for nortonlifelock */
    font-family: var(--nortonlifelockbody), sans-serif;
}

.App {
    text-align: center;
    min-height: 100vh;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.App-logo {
    width: 95vw;
    max-width: 500px;
}

.App-Banner {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: flex-start;
}

.choose-product-header {
    background: #FBEECA;
    padding: 20px;
    text-align: center;
    margin-top: 60px;
}

.choose-product-header .step-1 {
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
}

.choose-product-header .step-2,
.choose-product-header .step-3 {
    font-weight: 500;
    font-size: 20px;
    line-height: 28px;
    margin-top: 10px;
}

.version-banner-section {
    display: flex;
    align-items: center;
    height: 100%;
    justify-content: center;
}

.version-banner {
    display: grid;
    grid-template-columns: 1fr 1fr;
    justify-content: center;
    column-gap: 40px;
    margin: 50px 20px;
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    padding: 0 75px;
    height: fit-content;
}

.submission-link {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: space-around;
    align-items: center;
}

.submission-link a {
    font-weight: 800;
    font-size: 16px;
    line-height: 24px;
    color: #242424 !important;
    border: 2px solid #242424;
    border-radius: 22px;
    padding: 8px 16px;
    background: #FEEB29;
    text-decoration: none;
    cursor: pointer;
}

.submission-link a:hover {
    background: #FFF488;
}

.banner-header {
    font-weight: var(--bs-body-font-weight);
    font-size: var(--nortonlifelockHeaderFontSize, calc(12px + 2vmin));
    line-height: var(--bs-body-line-height);
    color: #FFFFFF;
}

.banner-1,
.banner-2 {
    display: flex;
    flex-direction: column;
    gap: 40px;
    justify-content: center;
    border: 1px solid #E4E4E4;
    border-radius: 20px;
    background: #E4E4E4;
    padding: 20px;
    padding-bottom: 20px;
    margin-bottom: 30px;
    flex-grow: 1;
    width: 100%;
}

.banner-1-section,
.banner-2-section {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    height: 100%;
    .image-header {
        font-size: 20px;
    }
    img {
        width: 500px;
        height: 340px;
        border-radius: 15px;
    }
}

.banner-1-section {
    border-right: 1px solid #E4E4E4;
    padding-right: 40px;
}

.banner-1 p,
.banner-2 p {
    margin-bottom: 0px;
    margin-top: 10px;
}

.banner-image {
    display: flex;
    flex-direction: column;
    color: #FFFFFF;
    align-items: center;
    width: 100%;
}

.choose-product-header p {
    margin-bottom: 0px;
}

.App .site-banner {
    display: flex;
    align-items: center;
    justify-content: center;
    background: #e4e4e4;
    padding: 16px;
    height: 300px;
    font-size: var(--nortonlifelockHeaderFontSize, calc(12px + 2vmin));
}

a.footer-link {
    text-decoration: none;
}

a.footer-link:link,
a.footer-link:visited {
    color: var(--nortonlifelocklight, #E4E4E4);
}

a.footer-link:hover {
    color: var(--nortonlifelockyellow, #FFC200);
}

a.header-link:link,
a.header-link:visited {
    color: var(--nortonlifelocklight, #E4E4E4);
}

a.header-link:hover {
    color: var(--nortonlifelockyellow, #FFC200);
}

a.header-link {
    text-decoration: none;
}

.App-logo-header {
    /* maximum width of logo is the original width of image */
    max-width: 300px;
    /* Width of logo is not bigger than width of screen */
    width: 95vw;
    height: auto;
    margin-bottom: 10px;
}

.App-logo-footer {
    /* maximum width of logo is the original width of image */
    max-width: 130px;
    /* Width of logo is not bigger than width of screen */
    width: 95vw;
    height: auto;
}

.App-footer-text {
    display: flex;
    align-items: baseline;
    justify-content: center;
    font-family: var(--nortonlifelockbody), sans-serif;
    font-size: var( --nortonlifelockFooterFontSize, calc(8px + 0.5vmin));
    color: var(--nortonlifelocklight, #E4E4E4);
}

.App-LogoSpace {
    height: 25px;
    width: 100%;
}

.App-Help {
    max-height: 16px;
    height: calc( 5px + 1vmin);
    width: auto;
    border-radius: 50%;
}

.App-LanguageNav {}

.App-LanguageNavDropdown {}

.App-LanguageNavDropdown a.nav-link {
    font-family: var(--nortonlifelockbody), sans-serif;
    font-size: var( --nortonlifelockMiniFontSize, calc(6px + 1vmin));
    background-color: var(--nortonlifelockdark, #242E39);
    color: var(--nortonlifelocklight, #E4E4E4);
}

.App-LanguageNavItem {
    background-color: var(--nortonlifelocklight, #E4E4E4);
    color: var(--nortonlifelockdark, #E4E4E4);
}

.App-LanguageNavItem a.nav-link {
    background-color: var(--nortonlifelocklight, #E4E4E4);
    color: var(--nortonlifelockdark, #E4E4E4);
}

.App-LanguageNavItem:hover {
    background-color: var(--nortonlifelockyellow, #FFC200);
    color: var(--nortonlifelockdark, #242E39);
}

.App-LanguageNavItem a.nav-link.active {
    background-color: var(--nortonlifelockyellow, #FFC200);
    color: var(--nortonlifelockdark, #242E39);
}

.App-LanguageSelectorContainer {
    width: 100%;
    display: flex;
    flex-direction: row-reverse;
}

.App-header {
    /* main heading font for nortonlifelock */
    font-family: var(--nortonlifelockheading), sans-serif;
    background-color: var(--nortonlifelockdark);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: var(--nortonlifelockHeaderFontSize, calc(12px + 2vmin));
    color: var(--nortonlifelocklight, #E4E4E4);
}

.App-footer {
    background-color: var(--nortonlifelockdark);
    border-top: 1px solid #E4E4E4;
}

.App-hr {
    /* color: #E4E4E4; */
}

.App-PortalContainer {
    max-width: 900px;
    width: 98vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: var(--nortonlifelockdark, #242E39);
}

.App-TabRow {}

.App-FormRow {
    max-width: 900px;
    width: 98vw;
    padding-left: 1vw;
    padding-right: 1vw;
}

.App-SubmissionSuccessResultText {
    font-size: var(--nortonlifelockHeaderFontSize, calc(12px + 2vmin));
    color: var(--nortonlifelocklight, #E4E4E4);
}


/*** NLLFN.jsx + NLLFP.jsx + URL.jsx ***/

.Portal-FormContainer {
    background-color: white;
    font-size: var( --nortonlifelockTextFontSize, calc(8px + 1vmin));
    text-align: left;
}

.Portal-DeprecationBanner {
    padding: 12px;
    background: #FEEB29;
    height: fit-content;
}

.Portal-DeprecationBanner p {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 0px;
}

.Portal-lockedURL {
    padding-top: 1vh;
    padding-bottom: 1vh;
}


/*** NLLURLSubmission.jsx ***/

.url-safeweb-icon-column {
    text-align: right;
}

.url-safeweb-icon-text-column {
    text-align: left;
    vertical-align: middle;
}

.url-success-text {
    text-align: center;
}

.url-safeweb-icon {
    /* maximum width of icon is the original width of image */
    max-width: 80px;
    min-width: 10px;
    /* Width of icon is is smaller with smaller screens */
    width: calc( 95vw / 10);
    height: auto;
}

.url-safeweb-container {
    justify-content: center;
    font-size: var( --nortonlifelockTextFontSize, calc(8px + 1vmin));
}

.url-thankyou {
    font-size: var( --nortonlifelockHeaderFontSize, calc(12px + 2vmin));
}


/*** common ***/

.Portal-FormHelpModal {
    max-width: 750px;
    width: 90vw;
}

.Portal-FormError {
    font-size: var(--nortonlifelockFormErrorFontSize, calc(8px + 0.5vmin));
    color: red;
}

.Portal-FormContainerInput {
    width: 100%;
    padding-top: 1vh;
    padding-bottom: 1vh;
    padding-left: 2px;
    padding-right: 2px;
}

.Portal-MessageToUser {
    width: 100%;
    padding-top: 1vh;
    padding-bottom: 1vh;
    font-size: var(--nortonlifelockTextFontSize, calc(8px + 1vmin));
    font-weight: bold;
    color: red;
    padding-left: 2px;
    padding-right: 2px;
}

.Portal-FormRowInputFooter {
    padding-top: 1vh;
    font-size: var(--nortonlifelockTextFontSize, calc(8px + 1vmin));
}

.Portal-FormRowText {
    padding-top: 1vh;
}

.Portal-FormRowInput {
    padding-top: 1vh;
}

.Portal-FormRowRadio {
    border-bottom: dotted;
    border-color: var(--nortonlifelockdark, #242E39);
    border-width: 1px;
    padding-top: 1vh;
}

.Portal-FormOptionExtraText {
    font-size: var(--nortonlifelockMiniFontSize, calc(8px + 1vmin));
}

.Portal-FormOptionHelpImage {
    max-width: 80vw;
    width: 90%;
    height: auto;
}

.Portal-FormRowSubmit {
    padding-top: 1vh;
    padding-left: 16px;
    padding-right: 16px;
}

.Portal-RowLegal {
    font-size: var(--nortonlifelockMiniFontSize, calc(6px + 1vmin));
    padding-top: 1vh;
    padding-left: 2px;
    padding-right: 2px;
}

.Portal-RowReCaptcha {
    padding-top: 1vh;
    padding-left: 2px;
    padding-right: 2px;
}


/*** buttons ***/

.Portal-GoBackButton {
    background-color: var(--nortonlifelocklight, #E4E4E4);
    color: var(--nortonlifelockdark, #242E39);
    border-color: var(--nortonlifelockdark, #242E39);
}

.Portal-GoBackButton:hover {
    background-color: var(--nortonlifelockdark, #242E39);
    color: var(--nortonlifelockyellow, #FFC200);
    border-color: var(--nortonlifelocklight, #E4E4E4);
}

.Portal-GoBackButton:active {
    background-color: var(--nortonlifelockdark, #242E39);
    color: var(--nortonlifelockyellow, #FFC200);
    border-color: var(--nortonlifelocklight, #E4E4E4);
}

.Portal-SubmitButton {
    background-color: var(--nortonlifelockyellow, #FFC200);
    color: var(--nortonlifelockdark, #242E39);
    border-color: var(--nortonlifelockdark, #242E39);
}

.Portal-SubmitButton:hover {
    background-color: var(--nortonlifelockdark, #242E39);
    color: var(--nortonlifelockyellow, #FFC200);
    border-color: var(--nortonlifelockdark, #242E39);
}

.Portal-SubmitButton:active {
    background-color: var(--nortonlifelockdark, #242E39);
    color: var(--nortonlifelockyellow, #FFC200);
    border-color: var(--nortonlifelockdark, #242E39);
}

.Portal-SubmissionSpinnerContainer {
    font-family: var(--nortonlifelockheading), sans-serif;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 2vh;
    padding-left: 1vw;
    padding-right: 1vw;
}

.Portal-SubmissionProgressBar {
    background-color: var(--nortonlifelockdark, #242E39);
    color: var(--nortonlifelockyellow, #FFC200);
    border-color: var(--nortonlifelockdark, #242E39);
}

.Portal-SubmissionSpinner {
    background-color: var(--nortonlifelockyyellow, #FFC200);
}

.Portal-SubmittingText {
    background-color: var(--nortonlifelockdark, #242E39);
    color: var(--nortonlifelockyellow, #FFC200);
    font-size: var(--nortonlifelockHeaderFontSize, calc(12px + 2vmin));
}


/*** tabs ***/

.nav-link {
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    cursor: hand;
    cursor: pointer;
    margin-top: 1vh;
    margin-left: 2px;
    margin-right: 2px;
    font-size: var( --nortonlifelockTabFontSize, calc(10px + 1vmin));
}

.nav-tabs a.nav-link {
    color: var(--nortonlifelocklight, #E4E4E4);
    border: 1px solid var(--nortonlifelocklight, #E4E4E4);
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

.nav-tabs a.nav-link:hover {
    color: var(--nortonlifelockyellow, #f7ba28);
}

.nav-tabs a.nav-link.active {
    color: var(--nortonlifelockdark, #242E39);
}

.page-loader-container {
    position: fixed;
    width: 100%;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 9999;
}

.page-loader {
    content: "";
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    top: 42%;
    width: 96px;
    height: 96px;
    margin: 0 auto;
    border: 5px solid #E4E4E4;
    border-radius: 50%;
    border-right-color: transparent;
    animation: page-loader-spin 500ms infinite linear;
    -o-animation: page-loader-spin 500ms infinite linear;
    -ms-animation: page-loader-spin 500ms infinite linear;
    -webkit-animation: page-loader-spin 500ms infinite linear;
    -moz-animation: page-loader-spin 500ms infinite linear;
}

@keyframes page-loader-spin {
    100% {
        transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@-o-keyframes page-loader-spin {
    100% {
        -o-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@-ms-keyframes page-loader-spin {
    100% {
        -ms-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@-webkit-keyframes page-loader-spin {
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@-moz-keyframes page-loader-spin {
    100% {
        -moz-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

/* Tab - Landscape */

@media (min-width: 960px) and (max-width: 1200px) {
    .banner-1-section,
    .banner-2-section {
        img {
            width: 400px;
            height: 280px;
        }
    }
}


/* Tab - Portrait */

@media (max-width: 960px) {
    .choose-product-header {
        margin-top: 40px;
    }
    .choose-product-header .step-1 {
        font-weight: 700;
        font-size: 20px;
        line-height: 28px;
    }
    /* .choose-product-header .step-2 {
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
    } */
    .version-banner {
        grid-template-columns: 1fr;
        row-gap: 40px;
        margin: 40px 30px;
        font-size: 20px;
        font-weight: 700;
        line-height: 28px;
    }
    .banner-1-section {
        border: 0px;
        padding-right: 0px;
        padding-bottom: 40px;
        border-bottom: 2px solid #E4E4E4;
    }
    .banner-1,
    .banner-2 {
        gap: 30px;
    }
}


/* Mobile */

@media (max-width: 585px) {
    .App {
        height: auto;
    }
    .App-logo {
        width: 85vw;
        max-width: 300px;
    }
    .App-logo-header {
        width: 85vw;
    }
    .version-banner {
        padding: 0px;
    }
    .logo-footer {
        margin-left: -18px;
        width: 100%;
    }
    .App-logo-footer {
        float: left;
    }
    .App-footer-text {
        margin-left: 18px;
        flex-wrap: wrap;
        justify-content: flex-start;
    }

    .banner-1-section,
    .banner-2-section {
        img {
            width: 380px;
            height: 275px;
        }
    }
}